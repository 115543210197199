.bodycopy {
    clear: both;
    margin-top: 23px;
    margin-bottom: 23px;
    padding: 10px 0 1px 0;
    max-width: 600px;
    //border-top: 5px solid #000;
    //border-top: 5px solid #929599;
    @include clearfix();

    &__head {
        margin: 0;
        padding: 0 0 20px 0;
        @include font-size(21);
        @include line-height(21);
        font-weight: bold;
    }

    &__inner {
        @include clearfix;
        @include font-size(15);
        @include line-height(18);
        text-align: justify;

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        p {
            @include font-size(15);
            @include line-height(18);
            margin: 0 0 18px 0;

            img {
                display: block;
                width: 100%;
                height: auto;
                margin-top: 30px;
            }

            &.large_text {
                font-size: 18px;
                line-height: 29px;
                padding-bottom: 10px;
            }
        }

        ul {
            @include list-reset;
            list-style: disc;
            padding-left: 14px;
            @include clearfix;

        }

        li {
            margin-bottom: 12px;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
            margin-bottom: 22px;
        }

        h2 {
            margin: 0;
            padding: 5px 0 25px 0;
            @include font-size(21);
            @include line-height(21);
            font-weight: bold;
            //text-transform: lowercase;
            text-transform: lowercase;
            color: #000;

            &:first-letter {text-transform: uppercase}
        }

        h3 {
            margin: 0;
            padding: 5px 0 25px 0;
            @include font-size(21);
            @include line-height(21);
            font-weight: bold;
            //text-transform: lowercase;
            text-transform: lowercase;
            &:first-letter {text-transform: uppercase}
            color: #000;
            text-align: left;
        }

        h4 {
            margin-top: 20px;
            font-size: 18px;
        }

        h5 {
            margin-top: 17px;
            font-size: 16px;
            font-weight: normal;
        }

        a {
            color: #025b96;
            text-decoration: underline;

            strong {
                font-weight: bold;
            }

            &:hover {
                color: #025b96;
                text-decoration: none;
            }
        }


        ul,
        ol {
            margin: 0;
            padding: 0 0 20px 22px;
            @include font-size(15);
            @include line-height(18);

            li {
                margin: 0;
                padding: 0 0 0 0;
                font-size: 16px;
                line-height: 19px;
                font-size: 16px;
                line-height: 22px;
                @include font-size(15);
                @include line-height(18);
            }
        }

        blockquote {
            float: left;
            display: block;
            border-top: 4px solid #ebc625;
            border-bottom: 4px solid #ebc625;
            padding: 25px 0;
            margin-left: -140px;
            max-width: 300px;
            margin-right: 30px;
        }
    }
}
