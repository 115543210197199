.breadcrumb {
    @include border-radius-separate(0, 0, 3px, 3px);
    background-color: #f3f3f3;
}

.breadcrumb-item a {
    color: #55585d;
    text-decoration: none;
    padding-bottom: 4px;

    @include hover-focus-active {
        text-decoration: none;
        color: #000;
        border-bottom: 2px solid #e7e7e7;
    }
}