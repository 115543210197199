.product-item {
    padding-bottom: 40px;

    &__image_link {
        display: block;
        max-width: 60%;
        margin: 30px auto 0 auto;

        @include min-screen(768px) {
            max-width: 100%;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
    }

    &__body {
        position: relative;
    }

    &__title {
        padding-bottom: 15px;

        @include min-screen(400px) {
            margin-right: 145px;
        }
    }

    &__title_link {
        color: #262527;
        @include font-size(18);
        font-weight: 400;
        text-decoration: none;
        font-weight: normal;
    }

    &__desc {
        @include font-size(14);
        color: #4D4D4D;
        padding-bottom: 15px;

        @include min-screen(400px) {
            margin-right: 145px;
        }
    }

    &__metadata {
        background-color: #dedede;
        height: 34px;
        overflow: hidden;
    }

    &__meta_link {
        float: right;
        display: block;
        line-height: 34px;
        overflow: hidden;
        padding: 0 20px;
        margin: 0;
        color: #848484;
        text-decoration: none;
        line-height: 36px;
        height: 34px;
        overflow: hidden;
        background-color: #dadada;
        @include font-size(13);

        @include hover-focus-active {
            background-color: #caccce !important;
            color: #fff;
        }
    }

    &__add_to_cart {
        float: right;
        background: #2e9df7;
        height: 34px;
        width: 130px;
        border: 0;
        color: #fff;
        text-align: center;
        line-height: 36px;
        text-decoration: none;
        overflow: hidden;
        @include font-size(14);

        @include hover-focus-active {
            background: #5fb4f9;
            color: #fff;
        }
    }

    &__details {
        position: absolute;
        right: 0;
        bottom: 34px;
        height: 34px;
        line-height: 36px;
        color: #333;
        overflow: hidden;
        text-align: center;
        background-color: #eceded;
        display: none;
        width: 130px;
        text-decoration: none;
        @include font-size(14);

        @include hover-focus-active {
            background-color: #caccce !important;
            color: #fff;
        }

        @include min-screen(400px) {
            display: block;
        }
    }

    &__price_info {
        position: relative;

        background: #5F6367;
        text-align: center;
        color: white;
        font-size: 15px;
        padding: 7px 0;

        @include min-screen(400px) {
            position: absolute;
            right: 0;
            bottom: 68px;
            width: 130px;
        }
    }

    &__price-old {
        color: #bbb;
        text-decoration: line-through;
    }

    &__price-new {
        font-weight: bold;
    }

    &__price-tax {
        font-size: 12px;
        font-weight: normal;
        color: #BBBBBB;
    }
}
