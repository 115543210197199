$brand-font: 'Roboto', sans-serif;
$brand-body-bkg: #fff;
$brand-error-color: #e30e49;

// overwrite bootstrap variables
$body-bg: $brand-body-bkg;

$brand-red: #ed145b;
$color-warning: #ed145b;

$font-family-headings: 'Roboto', sans-serif;
$font-family-body: 'Roboto', sans-serif;
$max-width: 2800px;
$font-color-primary: #2f444e;
$font-color-secondary: #acb1b4;
$overlay-bkg: rgba(47, 68, 78, 0.8);

$breakpoint_header: 768px;
$max-width: 1200px;

$color-font-primary: #21333a;
$color-font-secondary: #f21030;
$font-family-nav: 'Roboto', sans-serif;
