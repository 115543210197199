.logo {
    position: relative;
    display: inline-block;
    padding-bottom: 30px;
    margin: 0 auto 28px auto;
    overflow: hidden;

    @include min-screen(400px) {
        width: 300px;
        padding-bottom: 10px;
    }

    img {
        display: block;
    }

    .logo_slogan {
        position: absolute;
        bottom: 0;
        left: 57px;
        color: #7d8388;
        @include font-size(13);
        @include line-height(22);
        font-weight: normal;
        padding: 0;
        text-transform: uppercase;
    }
}
