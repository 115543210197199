*, *:before, *:after {
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
}

body {
    background-color: #fff;
    color: #000;
}

a,
button,
input:focus,
textarea:focus {
    outline: none !important;
}

html, body, td {
    @include font-size(15);
}

body {
    font-family: $brand-font;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    text-rendering: optimizeLegibility;
    font-smooth: always;
}

img.lazyload,
img.lazyloading {
    opacity: 0;
    transform: translateY(5px);
    @include as_transition(all, 0.5s);
}

img.lazyloaded {
    opacity: 1;
    transform: translateY(0);
    @include as_transition(all, 0.5s);
}

.swal-content {
    span {
        @include font-size(14);
        color: #000;
    }

    a {
        @include font-size(14);
        color: #1a7fc2;
    }
}
