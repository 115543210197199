
.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

body, td, th, input, textarea, select, a, p {
    font-size: 13px;
    line-height: 21px;
}

h1 {
    font-weight: 300;
}

.welcome {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: normal;
    text-shadow: 0 0 1px rgba(0, 0, 0, .01);
}

h1 {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: normal;
    color: #504f53;
    font-size: 28px;
    font-weight: 300;
}

h2_renamed {
    color: #3d3d3d;
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: normal;
}

h3_renamed {
    font-size: 16px;
    padding: 20px 0 5px 0;
    margin: 0;
}

p {
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 19px;
    line-height: 22px;
}

a, a b {
    color: #0E71CC;
    text-decoration: underline;
    cursor: pointer;
}

a:hover {
    text-decoration: none;
    color: #3d3d3d;
}

a img {
    border: none;
}

.banner_renamed {
    margin-right: 23px;
    margin-top: -2px;
    margin-bottom: 4px;
    transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
}

/* layout */
#top-container {
    /* width: 988px; */
    margin: 0 auto;
}

#column-left {
    float: left;
    width: 210px;
    margin-left: 32px;
    margin-top: 4px;
}

#column-right {
    float: right;
    width: 200px;
    margin-right: 32px;
}

#content {
    min-height: 400px;
    margin-bottom: 25px;
}

#column-left + #column-right + #content, #column-left + #content {
    margin-left: 265px;
    margin-right: 32px;
    margin-top: 15px;
}

#column-right + #content {
    margin-right: 230px;
}

#header {
    position: relative;
    z-index: 101;
    background: url(../image/blue-gradient.png) repeat-x left 35px;
    background: #f1f2f2;
    background: #fff;
    margin: 0;
    box-shadow: 0 2px 7px 0 hsla(0, 0%, 5%, .15);
}

#header .inner {
    position: relative;
    width: 928px;
    margin: 0 auto;
}

#header #logo {
    position: absolute;
    float: left;
    top: 22px;
    left: 10px;
    padding-top: 0px;
}

#header #logo a {
    display: block;
    height: 70px;
    overflow: hidden;
}

.header-separator {
    padding-bottom: 25px;
}

.separator {
    background: url(../image/header-separator.png) no-repeat -200px bottom;
    padding-bottom: 20px;
}

.header-contacts .phone {
    font-size: 13px;
    line-height: 22px;
    color: #3A3E42;
    color: #425d7c;
    text-align: right;
    padding-left: 28px;
    background: url(../image/phone-icon2.png) no-repeat left 2px;
    background: url(../image/icon-phone.png) no-repeat left 1px;
}

.header-contacts .email {
    color: #3A3E42;
    color: #425d7c;
    padding-right: 20px;
    line-height: 20px;
}

.header-contacts .email a {
    color: #3A3E42;
    color: #425d7c;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    padding-left: 29px;
    background: url(../image/email-icon2.png) no-repeat left 3px;
}

.header-contacts .email a:hover {
    color: #3A3E42;
    color: #0e71cc;

    text-decoration: underline;
}

#language {
    float: right;
    width: 122px;
    color: #999;
    line-height: 17px;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 11px;
}

#language img {
    cursor: pointer;
    margin-right: 5px;
}

#currency {
    float: right;
    width: 125px;
    color: #999;
    line-height: 11px;
    margin-top: 11px;
    margin-right: 52px;
    text-transform: uppercase;
    font-size: 11px;
}

#currency a {

    display: inline-block;
    padding: 2px 4px;
    text-decoration: none;
    margin-right: 2px;
    margin-bottom: 2px;
    font-size: 11px;
}

#currency a:hover {

    color: #3d3d3d;
}

#currency a b {
    color: #3d3d3d;
    text-decoration: none;
}

#cart_renamed {
    position: relative;
    clear: right;
    float: right;
    z-index: 180;
    margin: 4px 0 28px 20px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
}

#cart2 {
    background: url('../image/icon-basket.png') 12px 7px no-repeat;
    height: 30px;
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    font-family: 'Istok Web', Arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: Tahoma, Geneva, sans-serif;
    width: 38px;
    margin-right: 10px;
    margin-top: 0;
    border-right: 1px solid #8b8b8b;
}

#cart .heading {
    float: right;
    cursor: pointer;
    position: relative;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-family: 'Istok Web', Arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: Tahoma, Geneva, sans-serif;
    background: #5f6367;
    background: #1c6880;
    background: #7c7c7c url(../image/cart2-bkg.png) repeat-x left top;
    background: #404953;
    background: #425d7c;
    margin-right: 22px;
    margin-right: 0px;
    padding-right: 10px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
}

#cart .heading h4 {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 3px;
}

#cart .heading a {
    color: #F6F5F5;
    text-decoration: none;
    line-height: 19px;
}

#cart .heading a span {
    background: url('../image/arrow-down.png') 100% 50% no-repeat;
    padding-right: 15px;
}

#cart .content {
    position: absolute;
    top: 28px;
    right: 0;
    display: none;
    padding: 18px;
    min-height: 80px;
    max-width: 320px;
    margin-right: 0;
    background: #606367;
    color: #fdfdfd;
    z-index: 125;
}

#cart .content_inner {
    position: relative;
    z-index: 160;
}

#cart .mini-cart-info {
    position: relative;
}

#cart.active .heading {
    padding-bottom: 0px;
}

#cart.active .content { /* .active*/
    display: block;
    color: #fdfdfd;
}

.mini-cart-info table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 5px;
}

.mini-cart-info td {
    color: #fdfdfd;
    vertical-align: top;
    padding: 10px 5px;
    border-bottom: 1px solid #76797c;
}

.mini-cart-info td.name a {
    font-size: 11px;
    color: #fff;
}

.mini-cart-info td.name div small {
    font-size: 11px;
    line-height: 17px;
    color: #fff;
}

.mini-cart-info .image {
    width: 1px;
}

.mini-cart-info .image img {
    border: 1px solid #EEEEEE;
    text-align: left;
}

.mini-cart-info .name small {
    color: #666;
}

.mini-cart-info .quantity {
    text-align: right;
}

.mini-cart-info td.total {
    text-align: right;
    color: #fdfdfd;
    white-space: nowrap;
}

.mini-cart-info .remove {
    text-align: right;
}

.mini-cart-info .remove a {
    background: url(../image/remove-small.png) no-repeat left top;
    width: 11px;
    height: 11px;
    display: block;
    text-indent: -999px;
    overflow: hidden;
    text-align: left;
}

.mini-cart-info .remove a:hover {
    background-position: left bottom;
}

.mini-cart-info .remove img {
    cursor: pointer;
}

.mini-cart-total {
    text-align: right;
}

.mini-cart-total table {
    border-collapse: collapse;
    display: inline-block;
    margin-bottom: 5px;
}

.mini-cart-total td {
    color: #fff;
    padding: 4px;
}

#header #cart .checkout {
    clear: both;
    text-align: right;
}

#header #cart .empty {
    padding-top: 30px;
    text-align: center;
}

.social-buttons {
    float: right;
}

#top-bar {
    background: none;
    background: #f0f5f6;
    /* height: 40px; */
    padding-top: 8px;
    border-bottom: 1px solid #dfe1e3;
    border-bottom: 1px solid #e6ebec;
    border-bottom: 1px solid #ebf0f1;
    margin-left: 32px;
    margin-right: 32px;
    -webkit-border-radius: 0px 0px 2px 2px;
    -moz-border-radius: 0px 0px 2px 2px;
    -khtml-border-radius: 0px 0px 2px 2px;
    border-radius: 0px 0px 2px 2px;
    display: none;
}

#top-bar #welcome {
    /*
    position: absolute;
    top: 23px;
    right: 34px;
    */
    float: right;
    width: 298px;
    z-index: 5;
    text-align: right;
    color: #828282;
    font-family: Arial, Helvetica, sans-serif;
    font-family: 'Istok Web', Arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 11px;
    padding: 0 6px 0 0;
}

#top-bar #welcome a {
    float: right;
    display: block;
    padding: 0px 7px 0px 0px;
    color: #828282;
    color: #0e71cc;
    text-decoration: none;
    font-size: 1.2em;
    line-height: 1.3em;
}

#top-bar #welcome a:hover {
    color: #0e71cc;
    text-decoration: underline;
}

#top-bar #welcome a + a {
    margin-right: 8px;
    border-right: 1px solid #d9dadc;
}

.success, .warning, .attention, .information {
    padding: 10px 10px 10px 33px;
    color: #555555;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 32px;
    margin-left: 32px;
}

.success {
    background: #c8eeff url('../image/success.png') 10px center no-repeat;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    margin-top: 15px;
}

.warning {
    background: #FFD1D1 url('../image/warning.png') 10px center no-repeat;
    border: 1px solid #F8ACAC;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    margin-top: 15px;
}

.attention {
    background: #FFF5CC url('../image/attention.png') 10px center no-repeat;
    border: 1px solid #F2DD8C;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -khtml-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    margin-top: 15px;
}

.success .close, .warning .close, .attention .close, .information .close {
    float: right;
    padding-top: 4px;
    padding-right: 4px;
    cursor: pointer;
}

.required {
    color: #FF0000;
    font-weight: bold;
}

.error {
    display: block;
    color: #FF0000;
}

.help {
    color: #999;
    font-size: 10px;
    font-weight: normal;
    display: block;
}

table.form {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table.form tr td:first-child {
    width: 150px;
}

table.form > * > * > td {
    color: #000000;
}

table.form td {
    padding: 4px;
}

input.large-field,
select.large-field {
    width: 200px;
}

table.list {
    border-collapse: collapse;
    width: 100%;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    margin-bottom: 20px;
}

table.list td {
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
}

table.list thead td {
    background-color: #EFEFEF;
    padding: 0px 5px;
}

table.list thead td a, .list thead td {
    text-decoration: none;
    color: #222222;
    font-weight: bold;
}

table.list tbody td {
    vertical-align: top;
    padding: 0px 5px;
}

table.list .left {
    text-align: left;
    padding: 7px;
}

table.list .right {
    text-align: right;
    padding: 7px;
}

table.list .center {
    text-align: center;
    padding: 7px;
}

table.radio {
    width: 100%;
    border-collapse: collapse;
}

table.radio td {
    padding: 5px;
    vertical-align: top;
}

table.radio td label {
    display: block;
}

table.radio tr td:first-child {
    width: 1px;
}

table.radio tr td:first-child input {
    margin-top: 1px;
}

table.radio tr.highlight:hover td {
    background: #F1FFDD;
    //background-color: rgb(243, 246, 255);
    cursor: pointer;
}

.pagination {
    border-top: 1px solid #EEEEEE;
    padding-top: 8px;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.pagination .links {
    float: left;
}

.pagination .links a {
    display: inline-block;
    border: 1px solid #d3d3d3;
    padding: 4px 9px;
    text-decoration: none;
    color: #333;
    font-size: 0.9em;
}

.pagination .links a:hover {
    background: #f7f7f7;
}

.pagination .links b {
    display: inline-block;
    border: 1px solid #d3d3d3;
    padding: 4px 9px;
    font-weight: normal;
    text-decoration: none;
    color: #333;
    background: #e9e9e9;
    font-size: 0.9em;
}

.pagination .results {
    float: right;
    padding-top: 3px;
    font-size: 0.9em;
}

/*
.pagination {
	border-top: 1px solid #EEEEEE;
	padding-top: 8px;
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}

.pagination .links a {
	display: inline-block;
	line-height: 2.4em;
	height: 2.4em;
	text-decoration: none;
	color: #333;
	font-size: 0.9em;
	padding: 0 9px;
	border-color: #c6c6c6;
	background-color: #e9e9e9;
	background-image: none;
	-moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.20);
	-ms-box-shadow: inset 0 1px 1px rgba(0,0,0,.20);
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.20);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.20);
}
*/
/* button */
/*
a.button, input.button {
	cursor: pointer;
	color: #FFFFFF;
	line-height: 12px;
	font-size: 13px;
	background: #5EC8D9;
	border: 1px solid #20B6CE;
	border-radius:2px;
}

a.button,
input.button {
	cursor: pointer;
	color: #FFFFFF;
	line-height: 12px;
	font-size: 13px;
	background: #5EC8D9;
	border: 1px solid #20B6CE;
	border-radius:2px;
}

a.button {
	display: inline-block;
	text-decoration: none;
	padding: 6px 12px 6px 12px;
	transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition:  all 0.2s ease-out;
}
input.button {
	margin: 0;
	height: 26px;
	padding: 0px 12px 0px 12px;
	transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition:  all 0.2s ease-out;
}
a.button:hover, input.button:hover {
	border: 1px solid #1299af;
	background: #23a4c4;
	transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition:  all 0.2s ease-out;
}
*/

.button {
    cursor: pointer;
    background: #1CA6DF;
    background: #1b88cf;
    color: #FFFFFF !important;
    line-height: 19px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none !important;
    font-size: 13px;
    min-height: 32px;
    padding: 6px 2em 7px 2em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    border: none;
}

.button:hover {
    text-decoration: none;
    background: #1fb4f1;
}

.button:active {
    position: relative;
    top: 1px;
}

.buttons {
    padding: 0px;
}

.buttons .left {
    float: left;
    text-align: left;
}

.buttons .right {
    float: left;
    text-align: left;
}

.buttons .center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.htabs {
    clear: both;
    height: 36px;
    line-height: 16px;
    border-bottom: 1px solid #DDDDDD;
}

.htabs a {
    border-top: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    background: #f4f4f4;
    padding: 6px 15px 4px 15px;
    float: left;
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    margin-right: 4px;
    display: none;
    color: #333;
}

.htabs a:hover {
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    background: #e7e7e7;
    color: #333;
}

.htabs a.selected {
    cursor: default;
    padding-bottom: 9px;
    background: #FFFFFF;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    color: #333;
}

.tab-content {
    font-size: 14px;
    clear: left;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    padding: 15px;
    line-height: 20px;
    margin-bottom: 20px;
    z-index: 2;
    overflow: auto;
    background: #fff;
    color: #3D3D3D;
}

.tab-content ul {
    margin-top: 5px;
    margin-left: 0px;
    padding-left: 25px;
}

.tab-content h3 {
    font-size: 15px;
    padding: 16px 0 4px 0;
}

.tab-content h4 {
    font-size: 12px;
    padding: 10px 0 4px 0;
}

.tab-content p {
    line-height: 22px;
    font-size: 14px;
}

/* box */
.box {
    margin-bottom: 27px;
    clear: both;
}

#column-left .box .box-heading {
    border-bottom: 1px solid #e0dcd7;
    border-bottom: none;
    padding: 0px 10px 6px 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 13px;
    color: #333;
    text-transform: uppercase;
    margin-right: 0px;
}

#column-right .box .box-heading {
    border-bottom: 1px solid #e0dcd7;
    padding: 10px 10px 10px 10px;
    font-size: 20px;
    font-weight: normal;
    line-height: 14px;
    color: #333;
    text-transform: uppercase;
    margin-top: 15px;
}

.box .box-heading {
    padding: 10px 10px 10px 2px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    color: #505152;
    text-transform: uppercase;
    margin-right: 1px;
    margin-bottom: 7px;
    border-bottom: 1px solid #E0DCD7;
}

.box .box-content {
    -webkit-border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -khtml-border-radius: 0px 0px 7px 7px;
    border-radius: 0px 0px 7px 7px;
}

/* box products */
.box-product {
    width: 100%;
    overflow: hidden;
}

.box-product > div {
    /*background: #fff;*/
    /*
    -webkit-box-shadow: 0px 1px 0px 1px #f4f4f4;
    -moz-box-shadow: 0px 1px 0px 1px #f4f4f4;
    box-shadow: 0px 1px 0px 1px #f4f4f4;
    */
    /*display: inline-block;*/
    /*vertical-align: top;*/
    /*margin-right: 23px;*/
    /*margin-bottom: 2px;*/
    /*padding: 5px;*/
    /*padding: 0;*/
    /* border-top:1px solid #f4f4f4; */
}

.box-product img {
    display: block;
    width: 100%;
    height: auto;
}

.box-product > div.last {
    margin-right: 0;
}

.featured.box-product > div {
    margin-bottom: 23px;
}

.bestsellers > div {
    background: #fff;
    /*
    -webkit-box-shadow: 0px 1px 0px 1px #f4f4f4;
    -moz-box-shadow: 0px 1px 0px 1px #f4f4f4;
    box-shadow: 0px 1px 0px 1px #f4f4f4;
    */
    display: inline-block;
    vertical-align: top;

    margin: 6px 9px 2px 2px;

    padding: 5px;
    /* border-top:1px solid #f4f4f4; */

}

#column-left + #column-right + #content .box-product > div {
    width: 119px;
}

.box-product .image {
    display: block;
    margin-bottom: 5px;
    text-align: center;
}

.box-product.bestsellers .image {
    height: 209px;
}

#column-left .box-product .image {
    float: left;
}

#column-left .box-product .image img {
    width: 68px;
    padding: 2px;
}

#column-left .box-product .name {
    width: 90px;
    float: left;
    max-width: 95px;
    margin-top: 10px;
    margin-left: 17px;
}

#column-left .box-product .name a {
    font-size: 14px;
    width: 90px
}

#column-left .box-product .price {
    width: 110px;
    text-align: center;
}

#column-left .box-product .cart {
    display: none;
}

#column-left .box-product > div {
    margin-bottom: 5px;
    margin-right: 2px;
    margin-top: 4px;
    margin-left: 1px;
}

.box-product .image img {
    background: #fff;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.box-product .image img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.box-product .name a {
    color: #545454;
    text-decoration: none;
    display: block;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 170px;
    margin: 0 auto 10px auto;
}

.box-product .price {
    display: block;
    color: #11A1C5;
    color: #1a7fc2;
    font-size: 20px;
    font-weight: 300;
    float: left;
    margin-left: 8px;
    margin-top: 0px;
}

.box-product .price-old {
    color: #F00;
    text-decoration: line-through;
}

.box-product .price-new {
    font-weight: bold;
}

.box-product .rating {
    display: none;
    margin-bottom: 4px;
}

/* box category */

.box-category {
    margin-top: 0px;
    position: relative;
    left: -5px;
}

.box-category ul {
    list-style: none;
    margin: 0 0 0 -5px;
    padding: 0;
    display: block;
}

.box-category > ul > li {
    padding: 5px 0 3px 0px;
    margin-left: 0px;
    font-size: 13px;
}

.box-category > ul > li + li {
    border-top: 1px solid #EEEEEE;
    border-top: none;
}

.box-category > ul > li > a {
    text-decoration: none;
    color: #333;
    /* background: url(../image/arrow.jpg) no-repeat left; */
    padding-left: 18px;
    padding-left: 0;
    padding: 3px 0 2px 5px;
    font-size: 13px;
    display: block;
}

.box-category > ul > li > a:hover,
.box-category > ul > li ul > li > a:hover {
    color: #000;
}

.box-category > ul > li ul {
    display: none;
}

.box-category > ul > li a.active {
    font-weight: normal;
    color: #000;
    background-color: #f1f2f2;
    background-color: #eceded;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.box-category > ul > li a.active + ul {
    display: block;
    margin: 14px 0 14px 5px;
}

.sister-cat {
    display: block !important;
}

.box-category > ul > li ul > li {
    padding: 1px 0 4px 0px;
}

.box-category > ul > li ul > li > a {
    text-decoration: none;
    display: block;
    font-size: 13px;
    line-height: 17px;
    padding: 1px 0;
    color: #5f6367;
    color: #707479;
    display: block;
}

.box-category > ul > li ul > li > a.active {
    color: #000;
    background: none;
}

/* box content */
.box-content {
    margin-top: 0px;
}

.box-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.box-content > ul > li {
    padding: 10px 8px 10px 10px;

    margin-left: 5px;
    font-size: 13px;
}

.box-content > ul > li + li {
    border-top: 1px solid #EEEEEE;
}

.box-content > ul > li > a {
    text-decoration: none;
    color: #333;
    background: url(../image/arrow.jpg) no-repeat left;
    padding-left: 18px;
}

.box-content > ul > li > a:hover, .box-content > ul > li ul > li > a:hover {
    color: #4694e4;
}

.box-content > ul > li ul {
    display: none;
}

.box-content > ul > li a.active {
    font-weight: bold;
}

.box-content > ul > li a.active + ul {
    display: block;
}

.box-content > ul > li ul > li {
    padding: 5px 5px 0px 10px;
}

.box-content > ul > li ul > li > a {
    text-decoration: none;
    display: block;
}

.box-content > ul > li ul > li > a.active {
    font-weight: bold;
}

/* content */
#content .content {
    padding: 10px;
    overflow: auto;
    margin-bottom: 20px;
    line-height: 22px;
    color: #3d3d3d;

}

#content .log-content {
    padding: 10px 0px;
    overflow: auto;
    margin-bottom: 20px;
    border-top: 1px solid #EEEEEE;
    line-height: 22px;
    color: #3d3d3d;

}

#content .content .left {
    float: left;
    width: 49%;
}

#content .content .right {
    float: right;
    width: 49%;
}

/* category */
.category-info {
    overflow: auto;
    margin-bottom: 20px;
}

.category-info .image {
    float: left;
    padding: 5px;
    margin-right: 15px;
    border: 1px solid #E7E7E7;
}

.category-list {
    overflow: auto;
    margin-bottom: 20px;
    background: #FAFAFA;
    border: 1px solid #EEE;
}

.category-list ul {
    float: left;
    width: 18%;
}

.category-list .div a {
    text-decoration: underline;
    font-weight: bold;
}

/* manufacturer */
.manufacturer-list {
    border: 1px solid #DBDEE1;
    padding: 5px;
    overflow: auto;
    margin-bottom: 20px;
}

.manufacturer-heading {
    background: #F8F8F8;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 8px;
    margin-bottom: 6px;
}

.manufacturer-content {
    padding: 8px;
}

.manufacturer-list ul {
    float: left;
    width: 25%;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 10px;
}

/* product */
.product-filter {
    padding: 5px;
    overflow: auto;
    background: #f1f2f2;
    margin-top: 10px;
    margin-bottom: 20px;
}

.product-filter .display {
    margin-right: 15px;
    float: left;
    padding-top: 6px;
    padding-left: 6px;
    color: #333;
}

.product-filter .display a {
    font-weight: bold;
}

.product-filter .sort {
    float: right;
    color: #333;
}

.product-filter .limit {
    margin-left: 15px;
    float: right;
    color: #333;
}

.product-compare {
    padding-top: 6px;
    font-weight: normal;
}

.product-compare a {
    text-decoration: none;
    font-weight: normal;
}

.product-list_renamed .image img {
    padding: 3px;
    border: 1px none #E7E7E7;
    width: 130px;
    height: auto;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.product-list_renamed .name {
    margin-bottom: 35px;
    float: left;
    width: 375px;
    margin-top: -54px;
}

.product-list_renamed .name a {
    color: #444;
    color: #504f53;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    font-size: 18px;
    font-weight: normal;
}

.product-list_renamed .description {
    line-height: 19px;
    font-size: 12px;
    margin-bottom: 5px;
    color: #4D4D4D;
    float: left;
    width: 384px;
    margin-top: -20px;
}

.product-list_renamed .rating {
    color: #7B7B7B;
    float: left;
}

.product-list_renamed .price_renamed {
    text-align: center;
    color: white;
    font-size: 15px;
    position: relative;
    padding: 3px 0;
    min-width: 136px;
    background: #5F6367;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    padding-top: 4px;
}

.product-list_renamed .price-old {
    color: #bbb;
    text-decoration: line-through;
}

.product-list_renamed .price-new {
    font-weight: bold;
}

.product-list_renamed .price-tax {
    font-size: 12px;
    font-weight: normal;
    color: #BBBBBB;
}

.product-list_renamed .cart {
    margin-bottom: 3px;
}

.product-list_renamed .view-btn, .product-list_renamed .buy-btn {
    display: none;
}

.product-grid {
    width: 100%;
    overflow: hidden;
}

.product-grid > div {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-bottom: 15px;
    text-align: center;
    background: #fff;

    /*
    border-top: 1px solid #F4F4F4;
    -webkit-box-shadow: 0px 1px 0px 1px #F4F4F4;
    -moz-box-shadow: 0px 1px 0px 1px #f4f4f4;
    box-shadow: 0px 1px 0px 1px #F4F4F4;
    */
    padding: 5px;
    margin-left: 1px;
}

#column-left + #column-right + #content .product-grid > div {
    width: 125px;
}

.product-grid .image {
    display: block;
    margin-bottom: 5px;
}

.product-grid .image img {
    background: #fff;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.product-grid .image img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.product-grid .name {
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: 10px;
}

.product-grid .name a {
    color: #545454;
    text-decoration: none;
    display: block;
    margin: 0 auto 10px auto;
    font-size: 14px;
    line-height: 18px;
    width: 170px;
}

.product-grid .description {
    display: none;
}

.product-grid .rating {
    display: block;
    margin-bottom: 4px;
    display: none;
}

.product-grid .price {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    float: left;
    color: #11A1C5;
    margin-left: 8px;
}

.product-grid .price-old {
    color: #F00;
    text-decoration: line-through;
}

.product-grid .price-new {
    font-weight: bold;
}

.product-grid .price .price-tax {
    display: none;
}

.product-grid .cart {
    margin-bottom: 3px;
    width: 54px;
    float: right;
    margin-right: 8px;
}

.product-grid .wishlist, .product-grid .compare {
    margin-bottom: 3px;
    display: none;
}

.product-grid .wishlist a {
    color: #777;
    text-decoration: none;

    display: block;

}

.product-grid .compare a {
    color: #777;
    text-decoration: none;
    display: block;
}

.product-grid .buy-btn-list {
    display: none;
}

/* Product */
.product-info {
    //padding-top: 20px;
    //overflow: auto;
    margin-bottom: 20px;
}

.product-info > .left {
    float: left;
    margin-right: 20px;
}

.product-info .right {
    margin-left: 272px;
}

.product-info .image {
    border: 7px none #E7E7E7;
    float: left;
    margin-bottom: 20px;
    padding: 10px 10px 10px 0;
    text-align: center;
    background: white;
    margin-left: 0px;
    width: 450px;
    width: 100%;
}

.product-info .image a {
    display: block;
}


.product-info .image img {
    width: 100%;
    height: auto;
}


.product-info .image-additional a {
    text-align: center;
    float: left;
    display: block;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 100px;
    height: 100px;
}

.product-info .description {
    @include font-size(14);
    padding: 3px 0 10px 0;
}

.product-info .description span {
    @include line-height(18);
}

.product-info .description a {
    //color: #4D4D4D;
    color: #0E71CC;
    text-decoration: none;
}

.product-info .price {
    overflow: auto;
    padding: 20px 5px 10px 0;
    @include font-size(26);
    color: #0E71CC;
}

.product-info .price span {
    color: #0E71CC;
}

.product-info .price-old {
    color: #F00;
    text-decoration: line-through;
}

.product-info .price-new {
}

.product-info .price-tax {
    font-size: 16px;
    font-weight: normal;
    color: #999;
}

.product-info .price .reward {
    font-size: 12px;
    font-weight: normal;
    color: #999;
}

.product-info .price .discount {
    font-weight: normal;
    font-size: 12px;
    color: #4D4D4D;
}

.product-info .options {
    border-bottom: 1px solid #E7E7E7;
    padding: 0px 5px 10px 5px;
    margin-bottom: 10px;
    color: #000000;
}

.product-info .option-image {
    margin-top: 3px;
    margin-bottom: 10px;
}

.product-info .option-image label {
    display: block;
    width: 100%;
    height: 100%;
}

.product-info .option-image img {
    margin-right: 5px;
    border: 1px solid #CCCCCC;
    cursor: pointer;
}

.product-info .cart {

    padding: 12px 5px 0px 8px;
    margin-bottom: 15px;
    color: #4D4D4D;
    overflow: auto;

    border-top: 1px solid #DDD;
}

.product-info .cart div > span {
    padding-top: 12px;
    display: block;
    color: #999;
}

.product-info .cart .minimum {
    padding-top: 5px;
    font-size: 11px;
    color: #999;
    clear: both;
}

.product-info .review {
    color: #4D4D4D;
    border-top: 1px solid #E7E7E7;

    margin-bottom: 10px;
}

.product-info .review > div {
    padding: 10px;
    border-bottom: 1px solid #E7E7E7;
    line-height: 20px;
}

.product-info .review > div > span {
    color: #38B0E3;
}

.product-info .review .share {
    overflow: auto;
    line-height: normal;
}

.product-info .review .share a {
    text-decoration: none;
}

.review-list {
    padding: 10px;
    overflow: auto;
    margin-bottom: 20px;
    border: 1px solid #EEEEEE;
}

.review-list .author {
    float: left;
    margin-bottom: 20px;
}

.review-list .rating {
    float: right;
    margin-bottom: 20px;
}

.review-list .text {
    clear: both;
}

.attribute {
    border-collapse: collapse;
    width: 100%;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    margin-bottom: 20px;
}

.attribute thead td, .attribute thead tr td:first-child {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    background: #F7F7F7;
    text-align: left;
}

.attribute tr td:first-child {
    color: #000000;
    font-weight: bold;
    text-align: right;
    width: 20%;
}

.attribute td {
    padding: 7px;
    color: #4D4D4D;
    text-align: center;
    vertical-align: top;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
}

.compare-info {
    border-collapse: collapse;
    width: 100%;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    margin-bottom: 20px;
}

.compare-info thead td, .compare-info thead tr td:first-child {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    background: #F7F7F7;
    text-align: left;
}

.compare-info tr td:first-child {
    color: #000000;
    font-weight: bold;
    text-align: right;
}

.compare-info td {
    padding: 7px;
    width: 20%;
    color: #4D4D4D;
    text-align: center;
    vertical-align: top;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
}

.compare-info .name a {
    font-weight: bold;
}

.compare-info .price-old {
    font-weight: bold;
    color: #F00;
    text-decoration: line-through;
}

.compare-info .price-new {
    font-weight: bold;
}

/* wishlist */
.wishlist-info table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    margin-bottom: 20px;
}

.wishlist-info td {
    padding: 7px;
}

.wishlist-info thead td {
    color: #4D4D4D;
    font-weight: bold;
    background-color: #F7F7F7;
    border-bottom: 1px solid #DDDDDD;
}

.wishlist-info thead .image {
    text-align: center;
}

.wishlist-info thead .name, .wishlist-info thead .model, .wishlist-info thead .stock {
    text-align: left;
}

.wishlist-info thead .quantity, .wishlist-info thead .price, .wishlist-info thead .total, .wishlist-info thead .action {
    text-align: right;
}

.wishlist-info tbody td {
    vertical-align: top;
    border-bottom: 1px solid #DDDDDD;
}

.wishlist-info tbody .image img {
    border: 1px solid #DDDDDD;
}

.wishlist-info tbody .image {
    text-align: center;
}

.wishlist-info tbody .name, .wishlist-info tbody .model, .wishlist-info tbody .stock {
    text-align: left;
}

.wishlist-info tbody .quantity, .wishlist-info tbody .price, .wishlist-info tbody .total, .wishlist-info tbody .action {
    text-align: right;
}

.wishlist-info tbody .price s {
    color: #F00;
}

.wishlist-info tbody .action img {
    cursor: pointer;
}

.login-content {
    margin-bottom: 20px;
    overflow: auto;
    margin-top: 18px;
}

.login-content .left {
    float: left;
    width: 48%;
}

.login-content .right {
    float: right;
    width: 48%
}

.login-content .left .content, .login-content .right .content {
    min-height: 190px;
}

/* orders */
.order-list {
    margin-bottom: 10px;
}

.order-list .order-id {
    width: 49%;
    float: left;
    margin-bottom: 2px;
}

.order-list .order-status {
    width: 49%;
    float: right;
    text-align: right;
    margin-bottom: 2px;
}

.order-list .order-content {
    padding: 10px 0px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}

.order-list .order-content div {
    float: left;
    width: 33.3%;
    line-height: 19px;
}

.order-list .order-info {
    text-align: right;
}

.order-detail {
    background: #EFEFEF;
    font-weight: bold;
}

/* returns */
.return-list {
    margin-bottom: 10px;
}

.return-list .return-id {
    width: 49%;
    float: left;
    margin-bottom: 2px;
}

.return-list .return-status {
    width: 49%;
    float: right;
    text-align: right;
    margin-bottom: 2px;
}

.return-list .return-content {
    padding: 10px 0px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}

.return-list .return-content div {
    float: left;
    width: 33.3%;
}

.return-list .return-info {
    text-align: right;
}

.return-product {
    overflow: auto;
    margin-bottom: 20px;
}

.return-name {
    float: left;
    width: 31%;
    margin-right: 15px;
}

.return-model {
    float: left;
    width: 31%;
    margin-right: 15px;
}

.return-quantity {
    float: left;
    width: 31%;
}

.return-detail {
    overflow: auto;
    margin-bottom: 20px;
}

.return-reason {
    float: left;
    width: 31%;
    margin-right: 15px;
}

.return-opened {
    float: left;
    width: 31%;
    margin-right: 15px;
}

.return-opened textarea {
    width: 98%;
    vertical-align: top;
}

.return-captcha {
    float: left;
}

.download-list {
    margin-bottom: 10px;
}

.download-list .download-id {
    width: 49%;
    float: left;
    margin-bottom: 2px;
}

.download-list .download-status {
    width: 49%;
    float: right;
    text-align: right;
    margin-bottom: 2px;
}

.download-list .download-content {
    padding: 10px 0px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}

.download-list .download-content div {
    float: left;
    width: 33.3%;
}

.download-list .download-info {
    text-align: right;
}

/* cart */
.cart-info table {
    width: 100%;
    margin-bottom: 15px;
    border-collapse: collapse;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
}

.cart-info td {
    padding: 7px;
}

.cart-info thead td {
    color: #4D4D4D;
    font-weight: bold;
    background-color: #F7F7F7;
    border-bottom: 1px solid #DDDDDD;
}

.cart-info thead .image {
    text-align: center;
}

.cart-info thead .name, .cart-info thead .model, .cart-info thead .quantity {
    text-align: left;
}

.cart-info thead .price, .cart-info thead .total {
    text-align: right;
}

.cart-info tbody td {
    vertical-align: top;
    border-bottom: 1px solid #DDDDDD;
}

.cart-info tbody .image img {
    border: 1px solid #DDDDDD;
}

.cart-info tbody .image {
    text-align: center;
}

.cart-info tbody .name, .cart-info tbody .model, .cart-info tbody .quantity {
    text-align: left;
}

.cart-info tbody .quantity input[type='image'], .cart-info tbody .quantity img {
    position: relative;
    top: 4px;
    cursor: pointer;
}

.cart-info tbody .price, .cart-info tbody .total {
    text-align: right;
}

.cart-info tbody span.stock {
    color: #F00;
    font-weight: bold;
}

.cart-module > div {
    display: none;
}

.cart-total {
    border-top: 1px solid #DDDDDD;
    overflow: auto;
    padding-top: 8px;
    margin-bottom: 15px;
}

.cart-total table {
    float: right;
}

.cart-total td {
    padding: 3px;
    text-align: right;
}

/* checkout */
.checkout-heading {
    border-bottom: 1px dashed #dedede;
}

.checkout-card {
    background-color: #f8f9fa;
    border-radius: 15px;
}

//.checkout-step1,
//.checkout-step2,
//.checkout-step3,
//.checkout-step4,
//.checkout-step5,
//.checkout-step6,
//.checkout-step7 {
//    padding: 10px 0;
//    border-bottom: 1px solid #dedede;
//    background-size: 40px 40px;
//}
//
//.checkout-step1 {
//    background: url(../image/step1.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step2 {
//    background: url(../image/step2.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step3 {
//    background: url(../image/step3.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step4 {
//    background: url(../image/step4.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step5 {
//    background: url(../image/step5.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step6 {
//    background: url(../image/step6.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}
//
//.checkout-step7 {
//    background: url(../image/step7.png) no-repeat left 10px;
//    background-size: 40px 40px;
//}

.checkout-heading a {
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    margin-left: 20px;
    line-height: 22px;
    vertical-align: top;
}

.checkout-content .left {
    float: left;
    width: 48%;
}

.checkout-content .right {
    float: none;
    text-align: center;
}

.checkout-content .right .button {
    margin: 0 auto;
}

.checkout-content .buttons {
    clear: both;
}

.checkout-product table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    margin-bottom: 20px;
}

.checkout-product td {
    padding: 7px;
}

.checkout-product thead td {
    color: #4D4D4D;
    font-weight: bold;
    background-color: #F7F7F7;
    border-bottom: 1px solid #DDDDDD;
}

.checkout-product thead .name, .checkout-product thead .model {
    text-align: left;
}

.checkout-product thead .quantity, .checkout-product thead .price, .checkout-product thead .total {
    text-align: right;
}

.checkout-product tbody td {
    vertical-align: top;
    border-bottom: 1px solid #DDDDDD;
}

.checkout-product tbody .name, .checkout-product tbody .model {
    text-align: left;
}

.checkout-product tbody .quantity, .checkout-product tbody .price, .checkout-product tbody .total {
    text-align: right;
}

.checkout-product tfoot td {
    text-align: right;
    border-bottom: 1px solid #DDDDDD;
}

.contact-info {
    overflow: auto;
}

.contact-info .left {
    float: left;
    width: 48%;
}

.contact-info .right {
    float: left;
    width: 48%;
}

.sitemap-info {
    overflow: auto;
    margin-bottom: 40px;
}

.sitemap-info .left {
    float: left;
    width: 48%;
}

.sitemap-info .right {
    float: left;
    width: 48%;
}

.footer_nav {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    color: #fff;
}


/* banner */
.banner div {
    text-align: center;
    display: none;
}

.cycle-slideshow {
    width: 100%;
    margin-bottom: 20px;
}

.cycle-slideshow img {
    width: 100%;
    height: auto;
}

.cycle-slide {
    width: 100%;
}

.box-product .cart {
    float: right;
    margin-right: 8px;
}

.box-product .name {
    margin-left: 3px;
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: 10px;
}

.prod-title {
    color: #76706d;
    //border-bottom: 1px solid #DDD;
    padding-bottom: 8px;
    margin-bottom: 10px;
}

.prod-title h1 {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 14px;
}

#mobile-menu {
    display: none;
}

a.wishlist1 {
    padding-left: 20px;
    text-decoration: none;
    color: #777;
    background: url('../image/heart.png') left no-repeat;
    line-height: 18px;
}

a.wishlist1:hover {
    color: #343434;
    text-decoration: underline;
}

a.compare1 {
    padding-left: 20px;
    text-decoration: none;
    color: #777;
    background: url('../image/compare.png') left no-repeat;
}

a.compare1:hover {
    color: #343434;
    text-decoration: underline;
}

.prod-title a {
    text-decoration: none;
}

.contact-column ul li {
    list-style-type: none;
}

.contact-column ul {
    padding-left: 0;
    margin-left: -12px;
}

li.phone-cl {
    background: url('../image/mobile_phone.png') left no-repeat;
    line-height: 30px;
    padding-left: 25px;
    font-size: 18px;
    color: #777;
}

li.work-time-cl {
    line-height: 24px;
    padding-left: 25px;
    font-size: 14px;
    color: #777;
}

li.email-cl {
    background: url('../image/mail.png') left no-repeat;
    line-height: 30px;
    padding-left: 25px;
    font-size: 14px;
    color: #777;
}

li.address-cl {
    background: url('../image/home.png') left no-repeat;
    line-height: 30px;
    padding-left: 25px;
    font-size: 14px;
    color: #777;
}

#powered a img {
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.over-info {
    display: none;
    position: absolute;
    width: 200px;
    height: 264px;
    background: rgba(255, 255, 255, 0.8);
}

.zoom-icon {
    text-align: center;
    position: relative;
    display: block;
    padding-top: 100px;
    padding-bottom: 134px;
}

.view-btn {
    float: left;
    border-right: 1px solid #F4F4F4;
    padding-right: 8px;
}

.buy-btn {
    float: right;
    padding-left: 8px;
}

input:-webkit-autofill {
    background: #fff;
}

.log {
    padding-left: 0px;
}

#notification a {
    color: #0E71CC;
}

.checkout a, .mini-cart-info a {
    text-decoration: none;
}

.checkout a, .mini-cart-info a:hover {
    text-decoration: none !important;
}

.search-list .name {
    margin-top: 0px;
}

.search_filters {
    border: 1px solid #EEE;
    padding: 5px;
    overflow: auto;
    background: #FAFAFA;
    margin: 10px 0 20px 0;
}

.tags {
    margin-bottom: 10px;
}

.btn {
    text-decoration: none;
}

.btn.btn-primary {
    //background-color: #2e9df7;
}

/* custom table formatting */
.tab-content table {
    width: 90%;
    border-top: 1px solid #e5eff8;
    border-right: 1px solid #e5eff8;
    margin: 0 auto 1em auto;
    border-collapse: collapse;
}

.tab-content caption {
    color: #9ba9b4;
    font-size: .94em;
    letter-spacing: .1em;
    margin: 1em 0 0 0;
    padding: 0;
    caption-side: top;
    text-align: center;
}

.tab-content tr.odd td {
    background: #f7fbff
}

.tab-content tr.odd .column1 {
    background: #f4f9fe;
}

.tab-content .column1 {
    background: #f9fcfe;
}

.tab-content td {
    color: #3D3D3D;
    border-bottom: 1px solid #e5eff8;
    border-left: 1px solid #e5eff8;
    padding: .3em 1em;
    text-align: center;
}

.tab-content th {
    font-weight: normal;
    color: #3D3D3D;
    text-align: left;
    border-bottom: 1px solid #e5eff8;
    border-left: 1px solid #e5eff8;
    padding: 0.3em 1em;
}

.tab-content th p {
    padding: 0;
    margin: 0;
}

.tab-content thead th {
    background: #f4f9fe;
    text-align: center;
    font: bold 12px Arial, Helvetica, sans-serif;
    color: #66a3d3
}

.tab-content tfoot th {
    text-align: center;
    background: #f4f9fe;
}

.tab-content tfoot th strong {
    font: bold 12px Arial, Helvetica, sans-serif;
    margin: .5em .5em .5em 0;
    color: #66a3d3;
}

.tab-content tfoot th em {
    color: #f03b58;
    font-weight: bold;
    font-size: 1.1em;
    font-style: normal;
}

.content.register-details-wrap {
    width: 580px;
    width: 300px;
    vertical-align: top;
    display: inline-block;
    font-size: 13px;
}

.register-details-wrap table.form td {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
}

.register-details-wrap label {
    font-weight: normal;
    color: #373D49;
    font-size: 12px;
    padding-left: 1px;
}

.register-details-wrap input {
    font-size: 15px;
    color: #000;
}

.align_right {
    text-align: right;
}

.radio_wrapper {
    padding: 3px 0;
}

label {
    cursor: pointer;
    display: block;
    margin-bottom: 0.1875em;
}

.checkout label {
    //display: inline;
}

#payment-existing {
    padding-top: 10px;
}

/*
input[type='text'],
	input[type='password'],
	input[type='number'],
	input[type='search'],
	input[type='email'] {
		color: #000;
		font-size: 13px;
		padding: 5px 5px;
		line-height: 20px;
		outline: none;
		background-color: #fff;
		border: 1px solid #D1D3D9;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
*/
input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='email']:focus {
    border-color: #1CA6DF;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.register-details-wrap input,
.register-details-wrap textarea,
.register-details-wrap select {
    display: inline-block;
    margin-bottom: 0.375em;
    width: 100%;
}

.account_column {
    padding-top: 30px;
    float: left;
    width: 30%;
}

.account_column h2 {
    border-bottom: 1px solid #E0DCD7;
    font-size: 15px;
    margin-right: 30px;
}

#content .account_column .content {
    margin-left: 0;
    padding-left: 0;
}

.account_column ul,
.account_column li {
    list-style: none;
    margin: 0;
    padding: 0;
}

h3.head {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

h3.head.internal {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 8px 0;
}

.summary {
    font-size: 13px;
    line-height: 17px;
    padding: 4px 20px 10px 0;
}

.field-row {
    clear: left;
    padding: 0 0 6px 0;
}

.field-row label {
    width: 84px;
    padding-right: 5px;
    text-align: right;
    display: block;
    float: left;
    line-height: 29px;
    white-space: nowrap;
}

#tab-attribute table {
    width: 90% !important;
}

#tab-attribute table tr td:first-child,
#tab-attribute table tr th:first-child {
    width: 40% !important;
    font-weight: normal !important;
    text-align: left !important;
    color: #3D3D3D !important;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        /* Black prints faster:
                                          http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

.filters_list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.filters_list li.filter_group {
    margin: 0 !important;
    padding: 0 0 5px 0 !important;

}

.filters_list .filter_title {
    font-size: 14px;
    border-bottom: 1px solid #dedede;
    padding: 0 0 6px 0;
}

.filters_sublist {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0 !important;
    display: block !important;
}

.filters_sublist li {
    padding: 4px 0 !important;
    margin: 0 !important;
}

.filters_sublist .filter_checkbox {
    float: left;
    margin-top: 7px;
    margin-right: 4px;
}

.white-popup-block {
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    max-width: 600px;
    margin: 20px auto;
}

.popup-head {
    color: #000;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 25px;
}

.popup-bodycopy {
    color: #000;
}
