.header_contact {
    padding-top: 14px;
    @include clearfix;

    @include max-screen(768px) {
        margin-left: -5px;
        margin-right: -5px;
    }

    &__phone_wrap,
    &__email_wrap {
        padding: 0 5px;
        margin-bottom: 10px;
        float: left;
        width: 100%;

        @include min-screen(420px) {
            padding: 0 5px;
            float: left;
            width: 50%;
        }

        @include min-screen(768px) {
            float: none;
            display: inline-block;
            width: auto;
            padding-left: 0;
        }
    }

    &__phone,
    &__email {
        color: #000;
        text-decoration: none;
        display: inline-block;
        @include font-size(14);
        padding: 6px 10px;

        @include hover-focus-active {
            text-decoration: none;
            background: #f3f3f3;
            color: #000;
            @include border-radius(3px);
        }

        @include max-screen(768px) {
            display: block;
            @include font-size(14);
            text-align: center;
            background: #f3f3f3;
            @include border-radius(3px);

            @include hover-focus-active {
                background: #e7e7e7;
            }
        }
    }
}