//https://css-tricks.com/flexbox-bar-navigation/
.nav_main {
    &__wrap {
        clear: both;
        position: relative;
        background: #1a7fc2;
        border-top: 4px solid #1a7fc2;
        border-left: 4px solid #1a7fc2;
        border-right: 3px solid #1a7fc2;
        z-index: 200;
        @include clearfix;
        @include border-radius-separate(4px, 4px, 0, 0);

        @include hover-focus-active {
            .nav_main__list {
                display: block;
                opacity: 1;
            }
        }

        @include min-screen(768px) {
            @include hover-focus-active {
                .nav_main__list {
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }

    &__menu-icon {
        float: left;
        display: inline-block;
        width: 48px;
        height: 48px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        background: #5fb4f9 url(../../image/nav_collapsed2.png) no-repeat center center;
        border: none;
        @include border-radius(2px);
        //@include as_transition(all, 0.2s);

        @include min-screen(768px) {
            display: none;
        }

        @include hover-focus-active {
            //@include as_transition(all, 0.2s);
            background-color: #5fb4f9;
        }
    }

    &__menu {
        position: relative;
        z-index: 102;
        @include clearfix;
    }

    &__list {
        @include list-reset;
        @include clearfix;
        text-align: left;
        position: absolute;
        top: 0;
        left: 10px;
        margin-top: 57px;
        width: 220px;
        display: none;
        opacity: 0;
        z-index: 2000;
        background: #5fb4f9;

        @include min-screen(400px) {
            width: 260px;
        }

        //@include as_transition(all, 0.2s);

        @include min-screen(768px) {
            display: block;
            position: relative;
            float: left;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            width: 100%;
            opacity: 1;
            background: #1a7fc2;
            @include border-radius-separate(4px, 4px, 0, 0);

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__item {
        position: relative;
        z-index: 20;
        margin: 0;
        border-bottom: 2px solid #1a7fc2;
        @include border-radius-separate(0, 0, 0, 0);

        &.nav_main__item--active {
            .nav_main__link {
                background: #5fb4f9;
            }
        }

        @include hover-focus-active {
            .nav_main__link {
                background: #5fb4f9;
                color: #fff;
            }
        }

        @include min-screen(768px) {
            flex: 1 1 auto;
            border-bottom: none;

            @include hover-focus-active {
                .nav_main__submenu {
                    display: block !important;
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        @include font-size(13);
        @include line-height(24);
        font-weight: bold;
        letter-spacing: 0.5px;
        text-align: left;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        padding: 5px 15px 5px 15px;
        margin: 0;
        z-index: 6;
        width: 100%;

        @include hover-focus-active {
            background: #1a7fc2;
            color: #fff;
            @include border-radius-separate(3px, 3px, 0, 0);
        }

        @include min-screen(768px) {
            @include font-size(11);
            @include line-height(24);
            font-weight: bold;
            letter-spacing: 0.5px;
            padding: 2px 5px 2px 5px;
            text-align: center;

            @include hover-focus-active {
                background: #5fb4f9;
                color: #fff;
            }
        }

        @include min-screen(992px) {
            @include font-size(14);
            @include line-height(32);
            padding: 0px 15px 2px 15px;
            font-weight: normal;
        }
    }

    &__submenu {
        @include list-reset;
        left: auto;
        position: relative;
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 20px;
        background: #5fb4f9;

        @include min-screen(768px) {
            position: absolute;
            display: none;
            margin-left: 0;
        }
    }

    &__submenu_item {
        &.nav_main__submenu_item--active {
            .nav_main__submenu_link {
                background: #1a7fc2;
                color: #fff;
            }
        }
    }

    &__submenu_link {
        color: #fff;
        text-decoration: none;
        border: none;
        text-transform: none;
        text-decoration: none;
        padding: 3px 15px;
        color: #FFFFFF;
        display: block;
        min-width: 120px;
        background: none;
        white-space: nowrap;
        @include font-size(14);
        @include line-height(24);

        @include hover-focus-active {
            color: #fff;
            background: #5fb4f9;
            background: #1a7fc2;
        }

        @include min-screen(420px) {
            width: 280px;
            overflow: hidden;
        }
    }
}
