.search_form {
    padding: 0 0 15px 0;

    //&__button {
    //    display: none;
    //    float: left;
    //    background: url('../image/button-search-icon2.png') no-repeat center 7px;
    //    width: 30px;
    //    height: 28px;
    //    line-height: 28px;
    //    cursor: pointer;
    //    border: none;
    //    margin-left: -32px;
    //}

    //.search-submission-text {
    //    text-indent: -9999px;
    //    overflow: hidden;
    //    text-align: left;
    //    display: block;
    //}
}