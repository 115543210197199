.footer {
    clear: both;
    padding: 30px 0;
    background: #1a7fc2;

    h3 {
        color: #fff;
        text-transform: uppercase;
        font-size: 19px;
        @include font-size(19);
        @include line-height(13);
        letter-spacing: 0.5px;
        margin: 0;
        padding: 20px 0 20px 10px;
        border-bottom: 1px solid #fff;
    }

    .footer_nav {
        margin: 0;
        padding: 20px 0 22px 0;
        list-style: none;
        color: #fff;

        a {
            color: #fff;
            text-decoration: none;
            display: block;
            @include font-size(14);
            padding: 5px 10px;
            @include border-radius(2px);
            @include as_transition(all, 0.4s);

            @include hover-focus-active {
                text-decoration: none;
                background: #5fb4f9;
                transition: all 0.2s;
            }
        }

        .phone,
        .email {
            color: #fff;
            font-weight: 300;
            @include font-size(22);
            line-height: 32px;
            letter-spacing: 0.5px;
            padding: 0 0 5px 10px;
        }

        .phone {
            padding-top: 3px;
        }

        .email {
            @include font-size(19);
        }

        .work-time {
            color: #fff;
            @include font-size(14);
            line-height: 24px;
            padding: 20px 0 0 10px;
        }

        .work-time__numbers {
            @include font-size(24);
            font-weight: 300;
            padding: 6px 0 0 0;
        }
    }

    .copyrights {
        border-top: 1px solid #fff;
        color: #fff;
        @include font-size(14);
        padding: 23px 0 10px 10px;
    }
}