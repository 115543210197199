.product_images {
    clear: both;
    margin-left: 0px;
    overflow: hidden;
    float: left;

    &__link {
        display: block;
        margin-bottom: 30px;
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
        border: 3px solid #e7e7e7;
        @include border-radius(5px);
    }

    &__grid {
        @include clearfix;
    }
}


