.module_featured {
    clear: both;
    position: relative;
    margin-bottom: 30px !important;

    &__item {
        position: relative;
        min-height: 1px;
        margin-bottom: 30px !important;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

