.mini_cart {
    position: relative;
    clear: right;
    z-index: 180;
    margin-bottom: 20px;
    @include border-radius(3px);

    &__heading {
        position: relative;
        cursor: pointer;
        background: #1a7fc2;
        z-index: 1;
        margin-right: 0px;
        padding-right: 20px;
        color: #fff;
        @include font-size(14);
        @include line-height(29);
        @include border-radius(3px);

        @include hover-focus-active {
            background: #5fb4f9;
        }
    }

    &__icon {
        float: left;
        padding-bottom: 36px;
        width: 38px;
        background: url('../../image/icon-basket.png') no-repeat 15px center;
        margin-right: 10px;
    }
}
